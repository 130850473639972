$light-black: rgba(0, 0, 0, 0.2);
$primary-color: #da00ff;
$secondary-color: #000036;
$gray: #5e6878;
$highlighted-text: #00d6ff;
@mixin inner-bg {
    height: 100%;
    background-color: #FFF;
    border-radius: 10px;
    border: 1px solid #B3B3B3
}
@mixin loader($strokeValue, $durationMedium, $durationLarge) {
    .loader-animation {
      position: relative;
      display: flex;
      color: #999;
      font-size: 20px;
      margin: auto;
      justify-content: center;
      .outer {
        -webkit-animation: load 10s;
        animation: load 10s;
        stroke-dasharray: $strokeValue;
        transition: stroke-dashoffset 1s;
      }
      .loader-icon {
        position: absolute;
        width: 60px;
        height: 60px;
        background-color: #fff;
        border-radius: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .shape-outline {
        position: absolute;
        border: 2px solid rgba(#fff, .4);
        width: 90px;
        height: 90px;
        border-radius: 100%;
        z-index: -1;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }
    }
  
    @-webkit-keyframes load {
      from {
        stroke-dashoffset: 537;
      }
  
      to {
        stroke-dashoffset: 0;
      }
    }
  
    @keyframes load {
      from {
        stroke-dashoffset: 537;
      }
  
      to {
        stroke-dashoffset: 0;
      }
    }
  
    .circle-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 50%;
      &.medium {
        width: 120px;
        height: 120px;
        border: 1px solid rgba(#fff, .4);
      }
      &.large {
        width: 150px;
        height: 150px;
        border: 1px solid rgba(#fff, .1);
      }
      .circle1,
      .circle2,
      .circle3,
      .circle4 {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        margin: auto;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }
      .circle1,
      .circle2 {
        animation-duration: $durationMedium;
      }
      .circle3,
      .circle4 {
        animation-duration: $durationLarge;
      }
      .circle1 { 
        animation-name: circle1;
        background-color: #ff0067;
      }
      .circle2 {
        animation-name: circle2;
        background-color: #ffbd00;
      }
      .circle3 {
        animation-name: circle3;
        background-color: #00d6ff;
      }
      .circle4 {
        animation-name: circle4;
        background-color: #da00ff;
      }
    }
  
    @-webkit-keyframes circle1 {
      0% {
        transform: rotate(0deg) translate(-60px) rotate(0deg);
      }
  
      100% {
        transform: rotate(360deg) translate(-60px) rotate(-360deg);
      }
    }
  
    @keyframes circle1 {
      0% {
        transform: rotate(0deg) translate(-60px) rotate(0deg);
      }
  
      100% {
        transform: rotate(360deg) translate(-60px) rotate(-360deg);
      }
    }
  
    @-webkit-keyframes circle2 {
      0% {
        transform: rotate(-200deg) translate(-60px) rotate(-200deg);
      }
  
      100% {
        transform: rotate(160deg) translate(-60px) rotate(-160deg);
      }
    }
  
    @keyframes circle2 {
      0% {
        transform: rotate(-200deg) translate(-60px) rotate(-200deg);
      }
  
      100% {
        transform: rotate(160deg) translate(-60px) rotate(-160deg);
      }
    }
  
    @-webkit-keyframes circle3 {
      0% {
        transform: rotate(-150deg) translate(-75px) rotate(-150deg);
      }
  
      100% {
        transform: rotate(210deg) translate(-75px) rotate(-210deg);
      }
    }
  
    @keyframes circle3 {
      0% {
        transform: rotate(-150deg) translate(-75px) rotate(-150deg);
      }
  
      100% {
        transform: rotate(210deg) translate(-75px) rotate(-210deg);
      }
    }
  
    @-webkit-keyframes circle4 {
      0% {
        transform: rotate(-300deg) translate(-75px) rotate(-300deg);
      }
  
      100% {
        transform: rotate(60deg) translate(-75px) rotate(-60deg);
      }
    }
  
    @keyframes circle4 {
      0% {
        transform: rotate(-300deg) translate(-75px) rotate(-300deg);
      }
  
      100% {
        transform: rotate(60deg) translate(-75px) rotate(-60deg);
      }
    }
  }